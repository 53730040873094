<template>
  <div class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>业务中台</el-breadcrumb-item>
           <el-breadcrumb-item>优惠券</el-breadcrumb-item>
           <el-breadcrumb-item>优惠券列表</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
    <!-- 表头 -->
    <el-row class="content-box">
      <el-row class="search-box">
         <el-row class="search-row">
         <el-row class="search-item">
               <label>名称/编号：</label>
               <el-input class="width-220"  v-model="param.condition" placeholder="名称/业务编号" clearable></el-input>
         </el-row>
         <el-row class="search-item">
               <label>券类型：</label>
               <el-select v-model="param.type" clearable class="width-220" :placeholder="$t('msg.select')">
                  <el-option
                        v-for="(label, value) in dictData['coupon-type']"
                        :key="value"
                        :label="label"
                        :value="value">
                  </el-option>
               </el-select>
         </el-row>
         <el-row class="search-item">
            <label>创建时间：</label>
            <date-packer class="width-300" :datePacker="dateRange" @setDatePacker="getDatePacker"></date-packer>
         </el-row>
         <el-button class="bg-gradient" type="primary" icon="el-icon-search"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
            <el-button type="primary" icon="el-icon-refresh-right"  plain @click="handleQuery(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
         </el-row>
      </el-row>
      <!-- 主体内容 -->
      <el-row class="table-box">
         <div class="table-head-btn">
            <el-button-group class="m-right-10">
            <el-button :class="param.status === '' ? 'bg-gradient' : ''" @click="changeStatus('')" ><span>全部</span></el-button>
            <el-button :class="param.status === 'NOT_STARTED' ? 'bg-gradient' : ''" @click="changeStatus('NOT_STARTED')" ><span>未开始</span></el-button>
            <el-button :class="param.status === 'PROCESSING' ? 'bg-gradient' : ''" @click="changeStatus('PROCESSING')" ><span>进行中</span></el-button>
            <el-button :class="param.status === 'OVER' ? 'bg-gradient' : ''" @click="changeStatus('OVER')" ><span>已结束</span></el-button>
            <el-dropdown @command="handleCommand">
               <el-button
                     :type="param.status === 'TO_BE_REVIEWED' ||
                            param.status === 'OFF_THE_SHELF' ||
                            param.status === 'SUSPEND' ? 'primary' : ''">
                  {{ param.status | filterStatusQ }}<i class="el-icon-arrow-down el-icon--right"></i>
               </el-button>
               <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="TO_BE_REVIEWED">待审核</el-dropdown-item>
                  <el-dropdown-item command="OFF_THE_SHELF">已下架</el-dropdown-item>
                  <el-dropdown-item command="SUSPEND">已暂停</el-dropdown-item>
               </el-dropdown-menu>
            </el-dropdown>
         </el-button-group>
         </div>
         <!-- 表格 -->
         <el-table
               ref="mainTable"
               v-loading="loading"
               :data="tableData"
               :stripe="true"
               border fit>
            <el-table-column label="序号" width="60">
               <template slot-scope="scope">
                  {{scope.$index + 1}}
               </template>
            </el-table-column>
            <el-table-column label="优惠券名称" min-width="60">
               <template slot-scope="scope">
               <p style="color: red;text-decoration: underline;" @click="detailVisible = true">{{scope.row.mainTitle}}</p>
               </template>
            </el-table-column>
            <el-table-column label="类型" min-width="40">
               <template slot-scope="scope">
                  {{ scope.row.type | filterType(that) }}
               </template>
            </el-table-column>
            <el-table-column label="状态" min-width="40">
               <template slot-scope="scope">
                  {{ scope.row.status | filterStatus(that) }}
               </template>
            </el-table-column>
            <el-table-column prop="remark" show-overflow-tooltip label="优惠券内容" min-width="60"></el-table-column>
            <el-table-column prop="issueCount" label="发行量" min-width="40"></el-table-column>
            <el-table-column prop="couponCollection" label="领券量" min-width="40"></el-table-column>
            <el-table-column prop="usage" label="使用量" min-width="40"></el-table-column>
            <el-table-column label="有效期" min-width="70">
               <template slot-scope="scope">
                  <el-row v-if="scope.row.validType === 'APPOINT_DATE'">
                     <p>{{ scope.row.validDate }}</p>
                     <p>{{ scope.row.expiraDate }}</p>
                  </el-row>
                  <el-row v-else>
                     {{ scope.row.expiraDays }}天
                  </el-row>
               </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="50"></el-table-column>
            <el-table-column label="链接" min-width="50"></el-table-column>
            <el-table-column :label="$t('msg.operating')" fixed="right" min-width="100">
               <template slot-scope="scope">
                  <el-button  type="text" @click="handleDetail(scope.row.id)" >
                     <el-link type="primary" v-text="$t('msg.detail')">详情</el-link>
                  </el-button>
                  <el-button type="text" v-for="(item,key) in {  edit : '编辑', copy: '复制' }" :key="key"
                             @click="handleCopyEdit(key, scope.row)">
                     <el-link type="primary" >{{item}}</el-link>
                  </el-button>
                  <el-button v-if="scope.row.status !== 'NOT_STARTED' && scope.row.status !== 'PROCESSING'"
                             type="text" style="color: #F56C6C;"  @click="handleDel(scope.row.id)">
                     <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                  </el-button>
                  <el-button v-if="scope.row.status === 'NOT_STARTED' || scope.row.status === 'PROCESSING' "
                                type="text" style="color: #F56C6C;"  @click="handleStop(scope.row.id, 'SUSPEND')">
                      <el-link type="danger">停止发放</el-link>
                  </el-button>
                  <el-button v-if="scope.row.status === 'SUSPEND' "
                             type="text" style="color: #F56C6C;"  @click="handleStop(scope.row.id, 'PROCESSING')">
                     <el-link type="danger">开始发放</el-link>
                  </el-button>
                  <el-button type="text" style="color: #F56C6C;"  @click="handleLink(scope.row)">
                     <el-link type="primary">领劵链接</el-link>
                  </el-button>
               </template>
            </el-table-column>
         </el-table>
          <!-- 活动链接 -->
         <el-form label-width="105px" class="link-pup">
            <el-dialog title="活动链接" :visible.sync="linkVisible" width="500px">
               <el-row class="input-box">
                  <span>活动链接</span>
                  <el-input class="width-200" ></el-input>
                  <el-button class="bg-gradient" type="primary">复制</el-button>
               </el-row>
               <el-row class="ewm-box">
                     <el-col :span="12">
                        <span>二维码</span>
                        <el-button  @click="handleDownload(linkWechat)">下载</el-button>
                        <img class="ewm-img" v-if="linkWechat" :src="linkWechat" alt="">
                     </el-col>
                  <el-col :span="12">
                     <span>二维码</span>
                     <el-button  @click="handleDownload(linkApplets)">下载</el-button>
                     <img class="ewm-img" v-if="linkApplets" :src="linkApplets" alt="">
                  </el-col>
               </el-row>
               <el-row class="btm-btn">
                  <el-button  @click="linkVisible = false">关闭</el-button>
               </el-row>
            </el-dialog>
         </el-form>
         <!-- 详情 -->
         <el-form label-width="105px" class="popup-box">
            <el-dialog title="优惠券详情" :visible.sync="detailVisible" width="660px">
               <el-row class="tit">
                  <h6>{{ detailData.mainTitle }}</h6>
               </el-row>
               <el-row class="text-list">
                  <el-row>
                     <el-col :span="12">
                        <p><span class="width-100">优惠券类型</span>{{ detailData.type | filterType(that) }}</p>
                     </el-col>
                     <el-col :span="12">
                        <p><span class="width-100">参与商品数</span></p>
                     </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="12">
                        <p><span class="width-100">单用户限领数</span>{{ detailData.limitQuantity }}</p>
                     </el-col>
                     <el-col :span="12">
                        <p><span class="width-100">发行量</span>{{ detailData.issueCount }}</p>
                     </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="12">
                        <p><span class="width-100">活动开始时间</span>{{ detailData.validDate }}</p>
                     </el-col>
                     <el-col :span="12">
                        <p><span class="width-100">活动结束时间</span>{{ detailData.expiraDate }}</p>
                     </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="24">
                        <p><span class="width-100">优惠券详情</span>{{ detailData.remark }}</p>
                     </el-col>
                  </el-row>
               </el-row>
               <el-row class="btm-btn">
                  <el-button  @click="detailVisible = false">关闭</el-button>
               </el-row>
            </el-dialog>
         </el-form>
         <!-- 分页 -->
         <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import { getDict } from "@/common/js/common";
import { urlObj , business } from "@/api/interface"; // coupon
import { coupon } from '@/api/interface/business'
import { mapState } from 'vuex'
export default {
   data(){
      return{
         that: this,
         tableData: [],      // 数据表格
         loading: true,
         total: 1,
         limit: 1,
         page: 1,
         linkVisible: false,      // 领取链接弹窗
         detailVisible: false,    // 详情弹窗
         dateRange: [],      // 时间区间
         param: {
            status: '',
            type: '',
            condition: '',
            beginTime: '',
            endTime: ''
         },
         detailData: {
            mainTitle: '',
            type: '',
            limitQuantity: 1,
            issueCount: 1,
            validDate: '',
            expiraDate: '',
            remark: '',
         },
         linkWechat: '',
         linkApplets: '',
         // 提示文本
         del_success: "删除成功！",
         add_success: "添加成功！",
         update_success: '修改成功！',
         userName_no_null: '用户名不能为空！',
         mobile_no_null: '手机号不能为空！',
         nickName_no_null: '昵称不能为空！',
         confirm: '确定',
         cancel: '取消',
         confirm_remove: '确定移除？',
         prompt: '提示！',
      }
   },
   computed: {
      ...mapState(['hotelInfo', 'dictData'])
   },
   mounted() {
      getDict(['coupon-state', 'coupon-type'])
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.getCoupons()
   },
   methods: {
      // 获取优惠券列表
      getCoupons(){
         const url = coupon.couponPage + `?limit=${this.limit}&page=${this.page}`
         const param = { ...this.param, hotelId: this.hotelInfo.id }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               this.total = res.total
               this.tableData = []
               this.loading = false
               this.$nextTick(() => this.tableData = res.records)
               this.tableData.forEach(item => {
                  if(item.couponCollection !== undefined) item.couponCollection += 1
                  if(item.usage !== undefined) item.usage += 1
               })
            }
         })
      },
      // 获取查询活动时间
      getDatePacker(val){
         this.dateRange = val
         let [ beginTime = '', endTime = '' ] = val
         this.param.beginTime = beginTime
         this.param.endTime = endTime
      },
      // 查询优惠券
      handleQuery(bool){
         this.page = 1
         if (bool) return this.getCoupons()
         for (let k in this.param) this.param[k] = ''
         this.dateRange = []
      },
      // 改变优惠券状态
      changeStatus(val){
         this.page = 1
         this.param.status = val
         this.getCoupons()
      },
      // 优惠券其他状态
      handleCommand(val){
         this.page = 1
         this.param.status = val
         this.getCoupons()
      },
      // 删除优惠券
      handleDel(id){
         this.$confirm('您确定要删除当前优惠券活动吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            let url = coupon.delCoupon + `/${id}`
            this.$axios.get(url, {}).then(() => {
               this.$message({
                  showClose: true,
                  message: '删除成功！',
                  type: 'success'
               })
               this.getCoupons()
            })
         })
      },
      // 停止/开始发放
      handleStop(id, status) {
         this.$confirm(`您确定要${status === 'SUSPEND' ? '停止' : '开始'}发放优惠券吗？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            let url = coupon.couponStatus
            const param = { id, status }
            this.$axios.post(url, param).then(() => {
               this.$message({
                  showClose: true,
                  message: `${status === 'SUSPEND' ? '停止' : '开始'}发放成功！`,
                  type: 'success'
               })
               this.getCoupons()
            })
         })
      },
      // 改变每页数
      pageChange(num){
         this.limit = num
         this.getCoupons()
      },
      // 改变当前页
      handlePaging(num){
         this.page = num
         this.getCoupons()
      },
      // 优惠券详情
      handleDetail(id){
         const url = coupon.couponDetail + `/${id}`
         this.$axios.get(url, {}).then(res => {
            if (res.success) {
               this.detailVisible = true
               this.detailData = res.records
            }
         })
      },
      // 复制/编辑优惠券
      handleCopyEdit(action, row){
         this.$router.push({path: `/add_coupon/${row.type}`, query: { action, id: row.id }})
      },
      // 领券链接
      handleLink(row){
         this.linkVisible = true
         this.linkWechat = row.linkWechat
         this.linkApplets = row.linkApplets
      },
      // 下载二维码
      handleDownload(path){
         window.open(path)
      }
   },
   watch: {
      hotelInfo(newVal, oldVal) {
         oldVal && newVal.id !== oldVal.id && this.getCoupons()
      }
   },
   filters: {
      filterType(val, that){
         if (val && that.dictData['coupon-type']) return that.dictData['coupon-type'][val] || val
      },
      filterStatus(val, that){
         if (val && that.dictData['coupon-state']) return that.dictData['coupon-state'][val] || val
      },
      filterStatusQ(val){
         switch (val){
            case 'TO_BE_REVIEWED':
               return val = '待审核'
            case 'OFF_THE_SHELF':
               return val = '已下架'
            case 'SUSPEND':
               return val = '已暂停'
            default:
               return val = '其他'
         }
      }
   }
}
</script>

<style lang="scss" scoped>
.popup-box{
   .tit{
      text-align: center;
      h6{ font-size: 18px; margin-bottom: 10px }
      p{ font-size: 16px }
   }
   .right-price{ text-align: right; span{ font-size: 26px; color: red } }
   .table-box{ width: 70%; margin: 0 auto }
   .text-list{
      margin: 20px auto 0; line-height: 30px; color: #666666;
      span{ display: inline-block; text-align: right; margin-right: 15px; color: #222222 }
   }
   .btm-btn{ margin: 20px 0 0 0; text-align: center }
}
.link-pup{
   .input-box{
      margin: 20px 0 20px 0;
      span{ display: inline-block; width: 100px; text-align: right }
      .el-input, .el-button{ margin-left: 10px; }
   }
   .ewm-box{
      text-align: center; margin-bottom: 20px;
      .ewm-img{ width: 180px; height: 180px; margin: 10px 0 0 0 }
      span{ margin-right: 10px }
   }
}
.el-dropdown{
   border-left: solid 1px #DCDFE6;
}
</style>
